/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SendSmsOtpRequest = {
  type: SendSmsOtpRequest.type;
  phone: string;
};

export namespace SendSmsOtpRequest {

  export enum type {
    SMS_OTP = 'SMS_OTP',
  }


}

