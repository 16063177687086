/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateContractReviewerRequest = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
    },
    role: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    approvalCompetencyId: {
      type: 'string',
    },
    type: {
      type: 'ContractReviewerType',
    },
  },
} as const;
