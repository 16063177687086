/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RemoveCommentResponse = {
  type: 'all-of',
  contains: [{
    type: 'Response',
  }, {
    properties: {
      id: {
        type: 'string',
      },
      content: {
        type: 'string',
      },
    },
  }],
} as const;
