/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmailOtpVerifyRequest = {
  type: EmailOtpVerifyRequest.type;
  code: string;
  email: string;
};

export namespace EmailOtpVerifyRequest {

  export enum type {
    EMAIL_OTP = 'EMAIL_OTP',
  }


}

