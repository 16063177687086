/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListCommentsResponse = {
  type: 'all-of',
  contains: [{
    type: 'Response',
  }, {
    properties: {
      data: {
        type: 'any[]',
      },
      items: {
        properties: {
          id: {
            type: 'string',
            isRequired: true,
          },
          content: {
            type: 'string',
            isRequired: true,
          },
          authorEmail: {
            type: 'string',
            isRequired: true,
          },
          timestamp: {
            type: 'string',
            isRequired: true,
          },
          attachments: {
            type: 'array',
            contains: {
              type: 'File',
            },
          },
        },
      },
    },
  }],
} as const;
