/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SearchProfessionalBasicInfoResponse = {
  properties: {
    profession: {
      type: 'Profession',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
    },
    registerUF: {
      type: 'UF',
    },
    registerStatus: {
      type: 'ProfessionalRegisterStatus',
    },
    registerAdditionalData: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    birthDate: {
      type: 'string',
      isRequired: true,
      format: 'date',
    },
    board: {
      type: 'Board',
      isRequired: true,
    },
  },
} as const;
