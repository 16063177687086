/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $User = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
    },
    phone: {
      type: 'string',
    },
    workflow: {
      type: 'UserWorkflow',
      isRequired: true,
    },
    person: {
      type: 'Person',
    },
    professional: {
      type: 'ProfessionalInfo',
    },
  },
} as const;
