/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SearchProfessionalBasicInfoRequest = {
  properties: {
    registerNumber: {
      type: 'string',
    },
    profession: {
      type: 'string',
      isRequired: true,
    },
    registerUF: {
      type: 'string',
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
