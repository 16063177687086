/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BaseResponseCompany = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    document: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    numberOfMatches: {
      type: 'number',
      isRequired: true,
    },
    score: {
      type: 'number',
      isRequired: true,
    },
    result: {
      type: 'string',
      isRequired: true,
    },
    matchedRules: {
      type: 'array',
      contains: {
        type: 'all-of',
        contains: [{
          type: 'Rule',
        }],
      },
      isRequired: true,
    },
  },
} as const;
