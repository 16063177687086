/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanySaveStateSubscriptionInfoRequest = {
  properties: {
    citySubscriptionNumber: {
      type: 'string',
      isRequired: true,
    },
    stateSubscriptionNumber: {
      type: 'string',
    },
  },
} as const;
