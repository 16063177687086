/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListCommentsRequest = {
  properties: {
    entity: {
      type: 'string',
      isRequired: true,
    },
    entityId: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
