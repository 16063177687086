/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FacialRecognitionRequest = {
  properties: {
    provider: {
      type: 'BiometricsProvider',
      isRequired: true,
    },
    sdkToken: {
      type: 'string',
    },
    saffeEnd2EndId: {
      type: 'string',
    },
  },
} as const;
