/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateContractByIdJobRequest } from '../models/UpdateContractByIdJobRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContractApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Check and handle running contracts statuses
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public updateRunningContractsJob(): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/contract/update-running-contracts-job',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Check and handle contract update by Id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public updateContractByIdJob(
    requestBody: UpdateContractByIdJobRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/queue/contract/update-contract-by-id',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

}
