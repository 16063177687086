/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetCompanyMembersResponse = {
  type: 'all-of',
  contains: [{
    type: 'Response',
  }, {
    properties: {
      data: {
        properties: {
          person: {
            properties: {
              cpf: {
                type: 'string',
              },
            },
          },
          members: {
            type: 'array',
            contains: {
              type: 'CompanyMember',
            },
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
