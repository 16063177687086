/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAvailableDocumentsRequest } from '../models/GetAvailableDocumentsRequest';
import type { GetAvailableProfessionDocumentsResponseData } from '../models/GetAvailableProfessionDocumentsResponseData';
import type { GetProfessionRequest } from '../models/GetProfessionRequest';
import type { ProfessionInfo } from '../models/ProfessionInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProfessionApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns the available documents by profession
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public getProfessionAvailableDocuments(
    requestBody: GetAvailableDocumentsRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: Array<GetAvailableProfessionDocumentsResponseData>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/profession/get-available-documents',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

  /**
   * List all avaiable professions
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public listProfession(): CancelablePromise<{
    success: boolean;
    data?: Array<ProfessionInfo>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/profession/list',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Retrieve profession info by given id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public getProfession(
    requestBody: GetProfessionRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: ProfessionInfo;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/profession/info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

}
