/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PaginationRequest = {
  properties: {
    limit: {
      type: 'number',
    },
    offset: {
      type: 'number',
    },
  },
} as const;
