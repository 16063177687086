/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SaveProfessionalInfoRequest = {
  properties: {
    registerNumber: {
      type: 'string',
    },
    profession: {
      type: 'string',
      isRequired: true,
    },
    registerUF: {
      type: 'string',
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    gender: {
      type: 'Gender',
      isRequired: true,
    },
    maritalStatus: {
      type: 'MaritalStatus',
      isRequired: true,
    },
    rg: {
      type: 'string',
      isRequired: true,
    },
    rgState: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    phone: {
      type: 'string',
      isRequired: true,
    },
    nationality: {
      type: 'string',
      isRequired: true,
    },
    countryOrigin: {
      type: 'string',
      isRequired: true,
    },
    birthCity: {
      type: 'string',
      isRequired: true,
    },
    address: {
      type: 'AddressInfo',
    },
    board: {
      type: 'string',
    },
  },
} as const;
