/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListHiredContractsRequest = {
  properties: {
    search: {
      type: 'string',
    },
    sort: {
      type: 'string',
    },
  },
} as const;
