/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ComplianceDisapprovalRequest = {
  properties: {
    professionalId: {
      type: 'string',
      isRequired: true,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    denyType: {
      type: 'RegisterDenyType',
      isRequired: true,
    },
    reason: {
      type: 'string',
      isRequired: true,
    },
    document: {
      type: 'File',
    },
  },
} as const;
