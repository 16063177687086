/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContractStatus {
  CREATED = 'CREATED',
  WAITING_PROFESSIONAL_MEMBER_INFO = 'WAITING_PROFESSIONAL_MEMBER_INFO',
  FULFILLED = 'FULFILLED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}
