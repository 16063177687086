/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProfessionalDigitalCertificate {
  NONE = 'NONE',
  A1 = 'A1',
  A3 = 'A3',
  A1_AND_A3 = 'A1_AND_A3',
}
