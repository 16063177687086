/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalLogsInfoResponseData = {
  properties: {
    status_history: {
      type: 'array',
      contains: {
        type: 'StatusHistory',
      },
    },
  },
} as const;
