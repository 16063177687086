/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetDigitalCertificateRequest = {
  properties: {
    digitalCertificate: {
      type: 'ProfessionalDigitalCertificate',
      isRequired: true,
    },
  },
} as const;
