/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DenyDoctorExtraSpecialtiyRequest = {
  properties: {
    professionalId: {
      type: 'string',
      isRequired: true,
    },
    doctorExtraSpecialtyId: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
