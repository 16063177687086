/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Board {
  CRM = 'CRM',
  CRO = 'CRO',
  CRP = 'CRP',
  CFEP = 'CFEP',
  CREFITO = 'CREFITO',
  CREFONO = 'CREFONO',
  CREF = 'CREF',
  CRBM = 'CRBM',
  CRN = 'CRN',
  UBAAT = 'UBAAT',
  AATESP = 'AATESP',
  UBAM = 'UBAM',
  APEMESP = 'APEMESP',
  OMB = 'OMB',
  COREN = 'COREN',
  CRESS = 'CRESS',
  CRTR = 'CRTR',
}
