/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SmsOtpVerifyRequest = {
  type: SmsOtpVerifyRequest.type;
  code: string;
  phone: string;
};

export namespace SmsOtpVerifyRequest {

  export enum type {
    SMS_OTP = 'SMS_OTP',
  }


}

