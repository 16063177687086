/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DoctorCRMRegisterStatus {
  DEAD = 'DEAD',
  TRANSFERRED = 'TRANSFERRED',
  CANCELLED = 'CANCELLED',
  RETIRED = 'RETIRED',
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
  NO_WORK = 'NO_WORK',
  TEMPORARY_SUSPENSION = 'TEMPORARY_SUSPENSION',
  TOTAL_TEMPORARY_SUSPENSION = 'TOTAL_TEMPORARY_SUSPENSION',
  TOTAL_PRECAUTIONARY_INTERDICTION = 'TOTAL_PRECAUTIONARY_INTERDICTION',
  PARTIAL_PRECAUTIONARY_INTERDICTION = 'PARTIAL_PRECAUTIONARY_INTERDICTION',
  TOTAL_JURIDIC_SUSPENSION = 'TOTAL_JURIDIC_SUSPENSION',
  PARTIAL_JURIDIC_SUSPENSION = 'PARTIAL_JURIDIC_SUSPENSION',
  SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED',
  NON_OPERATIONAL = 'NON_OPERATIONAL',
  SUSPENDED_TOTAL = 'SUSPENDED_TOTAL',
  SUSPENDED_PARTIAL = 'SUSPENDED_PARTIAL',
  INVALID_VALUE = 'INVALID_VALUE',
}
