/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ContractSigner = {
  properties: {
    contractId: {
      type: 'string',
      isRequired: true,
    },
    signerId: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'ContractSignerStatus',
      isRequired: true,
    },
  },
} as const;
