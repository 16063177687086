/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FormRules = {
  properties: {
    type: {
      type: 'string',
    },
    length: {
      type: 'number',
    },
    allowMultiple: {
      type: 'string',
    },
  },
} as const;
