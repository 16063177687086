/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $NewSigner = {
  properties: {
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    phone: {
      type: 'string',
      isRequired: true,
    },
    zipCode: {
      type: 'string',
      isRequired: true,
    },
    street: {
      type: 'string',
      isRequired: true,
    },
    number: {
      type: 'string',
      isRequired: true,
    },
    complement: {
      type: 'string',
    },
    neighborhood: {
      type: 'string',
      isRequired: true,
    },
    city: {
      type: 'string',
      isRequired: true,
    },
    uf: {
      type: 'string',
      isRequired: true,
    },
    maritalStatus: {
      type: 'MaritalStatus',
      isRequired: true,
    },
    addressId: {
      type: 'string',
    },
    role: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
