/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CredentialsProvider {
  GOOGLE = 'google',
  AZUREAD = 'azuread',
  AMIL_AZUREAD = 'amil_azuread',
  LOGTO = 'logto',
}
