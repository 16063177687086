/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SaveCompanyRequest = {
  properties: {
    cnpj: {
      type: 'string',
    },
  },
} as const;
