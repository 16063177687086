/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BoardInfo = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    displayName: {
      type: 'string',
      isRequired: true,
    },
    validationInfo: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'BoardStatus',
      isRequired: true,
    },
  },
} as const;
