/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetBackgroundCheckResponseData = {
  properties: {
    biometriaReportIndex: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    biometriaReportConsultas: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    biometriaReportValidacoes: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    biometriaReportParametros: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    biometriaReportDados: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPfReportIndex: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPfReportConsultas: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPfReportValidacoes: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPfReportParametros: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPfReportDados: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPjReportIndex: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPjReportConsultas: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPjReportValidacoes: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPjReportParametros: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    qsaPjReportDados: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    pjReportIndex: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    pjReportConsultas: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    pjReportValidacoes: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    pjReportParametros: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    pjReportDados: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
  },
} as const;
