/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ManualFacialRecognitionRequest = {
  properties: {
    documentType: {
      type: 'Enum',
      isRequired: true,
    },
    documentNumber: {
      type: 'string',
      isRequired: true,
    },
    documentFrontImg: {
      type: 'File',
      isRequired: true,
    },
    documentBackImg: {
      type: 'File',
      isRequired: true,
    },
    faceImg: {
      type: 'File',
      isRequired: true,
    },
    nome: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
