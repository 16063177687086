/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AccreditationAnswer = {
  properties: {
    id: {
      type: 'string',
    },
    answer: {
      type: 'array',
      contains: {
        type: 'FormAnswer',
      },
      isRequired: true,
    },
    accreditationId: {
      type: 'string',
      isRequired: true,
    },
    questionId: {
      type: 'string',
      isRequired: true,
    },
    questionForm: {
      type: 'FormQuestion',
      isRequired: true,
    },
  },
} as const;
