/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CompanyRegistrationStatus {
  NULL = 'NULL',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  INAPT = 'INAPT',
  NON_REGULAR = 'NON_REGULAR',
  DOWN = 'DOWN',
}
