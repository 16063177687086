/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCRMMatchBypassRequest } from '../models/AddCRMMatchBypassRequest';
import type { ListCompanyMatchBypassResponse } from '../models/ListCompanyMatchBypassResponse';
import type { ListCRMMatchBypassResponse } from '../models/ListCRMMatchBypassResponse';
import type { Response } from '../models/Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SysService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return the list of company match bypasses
   * @returns ListCompanyMatchBypassResponse List of company match bypasses
   * @throws ApiError
   */
  public listCompanyMatchBypass(): CancelablePromise<ListCompanyMatchBypassResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sys/bypass/company/list',
    });
  }

  /**
   * Add a new bypass for the company match
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public addCompanyMatchBypass(
    requestBody: AddCRMMatchBypassRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sys/bypass/company/add',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Add a new bypass for the CRM match
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public addCrmMatchBypass(
    requestBody: AddCRMMatchBypassRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sys/bypass/crm/add',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the list of crm match bypasses
   * @returns ListCRMMatchBypassResponse List of CRM match bypasses
   * @throws ApiError
   */
  public listCrmMatchBypass(): CancelablePromise<ListCRMMatchBypassResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sys/bypass/crm/list',
    });
  }

}
