/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FormAnswer = {
  properties: {
    value: {
      type: 'string',
      isRequired: true,
    },
    additionalValue: {
      type: 'array',
      contains: {
        type: 'FormAnswerAdditionalValue',
      },
    },
    files: {
      type: 'array',
      contains: {
        type: 'File',
      },
    },
  },
} as const;
