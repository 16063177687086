/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ElementType {
  PERSON = 'PERSON',
  CALENDAR = 'CALENDAR',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  DOCUMENT = 'DOCUMENT',
  MAP = 'MAP',
  PERSON_PIN = 'PERSON_PIN',
  ADDRESS = 'ADDRESS',
  MEDICINE_BOX = 'MEDICINE_BOX',
}
