/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CompanyDocumentType {
  SOCIAL_CONTRACT = 'SOCIAL_CONTRACT',
  CITY_SUBSCRIPTION = 'CITY_SUBSCRIPTION',
  STATE_SUBSCRIPTION = 'STATE_SUBSCRIPTION',
  COMPANY_SUBSCRIPTION_ON_COUNSEL = 'COMPANY_SUBSCRIPTION_ON_COUNSEL',
  TAX_FORM = 'TAX_FORM',
}
