/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AccreditationQuestion = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    questionForm: {
      type: 'FormQuestion',
      isRequired: true,
    },
    professionId: {
      type: 'string',
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    description: {
      type: 'string',
      isRequired: true,
    },
    required: {
      type: 'boolean',
      isRequired: true,
    },
    status: {
      type: 'string',
      isRequired: true,
    },
    formGroup: {
      type: 'string',
      isRequired: true,
    },
    orderNumber: {
      type: 'number',
      isRequired: true,
    },
  },
} as const;
