/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Response = {
  description: `This schema/type is meant to be extended by specific response schemas/types, as generic schemas notation is not supported by the OpenAPI 3.x spec. In the endpoint responses you MUST reference a custom reponse schema that is composed by this Response schema, plus a data field that defines the data responded by the endpoint. See more on model composition at https://swagger.io/docs/specification/data-models/inheritance-and-polymorphism/`,
  properties: {
    success: {
      type: 'boolean',
      isRequired: true,
    },
    error: {
      type: 'Error',
    },
  },
} as const;
