/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccreditationAnswer } from '../models/AccreditationAnswer';
import type { AccreditationDocument } from '../models/AccreditationDocument';
import type { GetAccreditationInfoResponse } from '../models/GetAccreditationInfoResponse';
import type { GetAvailableAccreditationDocumentsResponse } from '../models/GetAvailableAccreditationDocumentsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccreditationApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Save a professional's accreditation
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public saveAccreditation(): CancelablePromise<{
    success: boolean;
    data?: {
      accreditationId: string;
    };
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/accreditation/save-accreditation',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Gets a professional's accreditation info
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public getAccreditationInfo(): CancelablePromise<{
    success: boolean;
    data?: GetAccreditationInfoResponse;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/accreditation/get-accreditation-info',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Gets the available accreditation documents for users profession
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public getAvailableAccreditationDocuments(): CancelablePromise<{
    success: boolean;
    data?: GetAvailableAccreditationDocumentsResponse;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/accreditation/get-available-documents',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save a professional's accreditation info
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public saveAccreditationInfo(
    requestBody: {
      accreditationDocuments: Array<AccreditationDocument>;
      accreditationAnswers: Array<AccreditationAnswer>;
    },
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/accreditation/save-accreditation-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

}
