/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MarketplaceProfessionalInfoResponseData = {
  properties: {
    uuid: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    cnpj: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    updatedAt: {
      type: 'string',
      isRequired: true,
    },
    register: {
      type: 'ProfessionalRegister',
    },
    specialties: {
      type: 'array',
      contains: {
        type: 'ProfessionalSpecialty',
      },
      isRequired: true,
    },
    extraSpecialties: {
      type: 'array',
      contains: {
        type: 'ProfessionalExtraSpecialty',
      },
      isRequired: true,
    },
    professionId: {
      type: 'Profession',
      isRequired: true,
    },
    professionDisplayName: {
      type: 'string',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
    },
    registerUF: {
      type: 'string',
    },
    board: {
      type: 'Board',
    },
    person: {
      type: 'Person',
      isRequired: true,
    },
    professionalDocuments: {
      type: 'array',
      contains: {
        type: 'ProfessionalInfoDocument',
      },
      isRequired: true,
    },
    company: {
      type: 'Company',
    },
  },
} as const;
