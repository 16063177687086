/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalComplianceInfoResponseData = {
  properties: {
    compliance_questions: {
      properties: {
      },
    },
    score: {
      type: 'CompanyScore',
    },
    hasUnsuccessfulScoreRequests: {
      type: 'boolean',
    },
  },
} as const;
