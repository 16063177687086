/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyBankAccount = {
  properties: {
    bankCode: {
      type: 'string',
      isRequired: true,
    },
    branchNumber: {
      type: 'string',
      isRequired: true,
    },
    accountNumber: {
      type: 'string',
      isRequired: true,
    },
    accountDigit: {
      type: 'string',
      isRequired: true,
    },
    accountType: {
      type: 'BankAccountType',
      isRequired: true,
    },
    branchDigit: {
      type: 'string',
    },
    status: {
      type: 'CompanyBankAccountStatus',
    },
  },
} as const;
