/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyScore = {
  type: 'all-of',
  contains: [{
    type: 'BaseResponseCompany',
  }, {
    properties: {
      scorePartners: {
        type: 'number',
        isRequired: true,
      },
      resultFinal: {
        type: 'string',
        isRequired: true,
      },
      scoreFinal: {
        type: 'number',
        isRequired: true,
      },
      partners: {
        type: 'array',
        contains: {
          type: 'BaseResponseCompany',
        },
        isRequired: true,
      },
      totalPartners: {
        type: 'number',
      },
      totalPartnersWithMatches: {
        type: 'number',
      },
    },
  }],
} as const;
