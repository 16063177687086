/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdminValidationMessage = {
  properties: {
    content: {
      type: 'string',
      isRequired: true,
    },
    level: {
      type: 'AdminValidationMessageLevel',
      isRequired: true,
    },
  },
} as const;
