/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WorkflowStepId {
  ONBOARDING_DOCTOR_BASIC_INFO = 'ONBOARDING_DOCTOR_BASIC_INFO',
  ONBOARDING_DOCTOR_CONFIRM_BASIC_INFO = 'ONBOARDING_DOCTOR_CONFIRM_BASIC_INFO',
  ONBOARDING_PERSONAL_ADDRESS = 'ONBOARDING_PERSONAL_ADDRESS',
  ONBOARDING_FACIAL_RECOGNITION = 'ONBOARDING_FACIAL_RECOGNITION',
  ONBOARDING_PROFESSIONAL_DOCUMENTS = 'ONBOARDING_PROFESSIONAL_DOCUMENTS',
  ONBOARDING_COMPANY_SEARCH_INFO = 'ONBOARDING_COMPANY_SEARCH_INFO',
  ONBOARDING_COMPANY_CONFIRM_INFO = 'ONBOARDING_COMPANY_CONFIRM_INFO',
  ONBOARDING_COMPANY_DOCUMENTS = 'ONBOARDING_COMPANY_DOCUMENTS',
  ONBOARDING_COMPANY_BANK_INFO = 'ONBOARDING_COMPANY_BANK_INFO',
  ONBOARDING_COMPLIANCE = 'ONBOARDING_COMPLIANCE',
  ONBOARDING_USE_TERMS = 'ONBOARDING_USE_TERMS',
}
