/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SendEmailOtpRequest = {
  type: SendEmailOtpRequest.type;
  email: string;
};

export namespace SendEmailOtpRequest {

  export enum type {
    EMAIL_OTP = 'EMAIL_OTP',
  }


}

