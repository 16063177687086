/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Element = {
  properties: {
    type: {
      type: 'ElementType',
      isRequired: true,
    },
    width: {
      type: 'number',
      isRequired: true,
    },
    height: {
      type: 'number',
      isRequired: true,
    },
    color: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
