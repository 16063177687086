/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Filter = {
  properties: {
    status: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    crmUFs: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    providerIds: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    specialties: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
    },
    registerUFs: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    professionIds: {
      type: 'array',
      contains: {
        type: 'Profession',
      },
      isRequired: true,
    },
  },
} as const;
