/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserWorkflowBasic = {
  properties: {
    workflow: {
      type: 'Workflow',
      isRequired: true,
    },
    status: {
      type: 'WorkflowStatus',
      isRequired: true,
    },
    steps: {
      type: 'array',
      contains: {
        type: 'UserWorkflowStep',
      },
      isRequired: true,
    },
    statusReason: {
      type: 'string',
    },
  },
} as const;
