/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyBankAccount } from '../models/CompanyBankAccount';
import type { CompanyDocumentConfig } from '../models/CompanyDocumentConfig';
import type { CompanyGetTaxesResponse } from '../models/CompanyGetTaxesResponse';
import type { CompanySaveDocumentsRequest } from '../models/CompanySaveDocumentsRequest';
import type { CompanySaveStateSubscriptionInfoRequest } from '../models/CompanySaveStateSubscriptionInfoRequest';
import type { CompanySearchInfoRequest } from '../models/CompanySearchInfoRequest';
import type { CompanySearchInfoResponse } from '../models/CompanySearchInfoResponse';
import type { GetCompanyResponse } from '../models/GetCompanyResponse';
import type { OpenCompanyEmailResponse } from '../models/OpenCompanyEmailResponse';
import type { Response } from '../models/Response';
import type { SaveCompanyRequest } from '../models/SaveCompanyRequest';
import type { SaveCompanyResponse } from '../models/SaveCompanyResponse';
import type { SaveCompanyTaxesRequest } from '../models/SaveCompanyTaxesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieves an existing company basic information based on its registration number(CNPJ)
   * @param requestBody
   * @returns CompanySearchInfoResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public searchCompanyInfo(
    requestBody: CompanySearchInfoRequest,
  ): CancelablePromise<CompanySearchInfoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/searchCompanyInfo',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Saves main company information and links it to the professional
   * @param requestBody
   * @returns SaveCompanyResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public saveCompanyInfo(
    requestBody: SaveCompanyRequest,
  ): CancelablePromise<SaveCompanyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/saveCompanyInfo',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Save the bank information of a company
   * @param requestBody
   * @returns any Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public saveCompanyBank(
    requestBody: CompanyBankAccount,
  ): CancelablePromise<(Response & {
    metadata?: {
      nextUrl?: string;
    };
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/saveCompanyBank',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Saves information related to the taxes that a existing company needs to declare
   * @param requestBody
   * @returns Response Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public saveCompanyTaxes(
    requestBody: SaveCompanyTaxesRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/saveCompanyTaxes',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list od elegible tax for a given company based on its current info
   * @returns CompanyGetTaxesResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public getCompanyTaxes(): CancelablePromise<CompanyGetTaxesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/getCompanyTaxes',
    });
  }

  /**
   * Save company documents
   * @param requestBody
   * @returns any Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public saveCompanyDocuments(
    requestBody: CompanySaveDocumentsRequest,
  ): CancelablePromise<(Response & {
    metadata?: {
      nextUrl?: string;
    };
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/saveCompanyDocuments',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Saves a company informations, named 'Inscrição Municipal' and 'Inscrição Estadual'(optional)
   * @param requestBody
   * @returns Response Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public saveStateSubscriptionInfo(
    requestBody: CompanySaveStateSubscriptionInfoRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/saveCompanyStateSubscription',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get all documents available/pending for a company, togheter with a status for each one
   * @returns GetCompanyResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public getCompany(): CancelablePromise<GetCompanyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/getCompany',
    });
  }

  /**
   * Returns the company available documents
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public getCompanyAvailableDocuments(
    requestBody: {
      professionId?: string;
    },
  ): CancelablePromise<{
    success: boolean;
    data?: Array<CompanyDocumentConfig>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/get-available-documents',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Receive the user's information for sending an email to accounting
   * @returns OpenCompanyEmailResponse Returns success/APIError
   * @throws ApiError
   */
  public openCompanyEmail(): CancelablePromise<OpenCompanyEmailResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/company/openCompanyEmail',
    });
  }

}
