/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ExportReportRequest = {
  properties: {
    search: {
      type: 'string',
    },
    sorting: {
      properties: {
        sort: {
          type: 'string',
        },
        order: {
          type: 'string',
        },
      },
    },
    filters: {
      properties: {
        status: {
          type: 'array',
          contains: {
            type: 'string',
          },
        },
        crmUF: {
          type: 'array',
          contains: {
            type: 'string',
          },
        },
        providersIds: {
          type: 'array',
          contains: {
            type: 'string',
          },
        },
        specialties: {
          type: 'array',
          contains: {
            type: 'string',
          },
        },
      },
    },
  },
} as const;
