/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListApprovalCompetenciesRequest = {
  properties: {
    value: {
      type: 'number',
    },
  },
} as const;
