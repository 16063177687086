/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Error = {
  properties: {
    code: {
      type: 'ErrorCode',
      isRequired: true,
    },
    message: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
