/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserSignInResponseData = {
  properties: {
    accessToken: {
      type: 'string',
      isRequired: true,
    },
    idToken: {
      type: 'string',
      isRequired: true,
    },
    expirationTimestamp: {
      type: 'number',
      isRequired: true,
    },
    newUser: {
      type: 'boolean',
      isRequired: true,
    },
  },
} as const;
