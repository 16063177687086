/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalUpdateComplianceScoreResponseData = {
  properties: {
    score: {
      type: 'CompanyScore',
    },
  },
} as const;
