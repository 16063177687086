/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RemoveCommentAttachmentRequest = {
  properties: {
    attachmentIds: {
      type: 'one-of',
      contains: [{
        type: 'string',
      }, {
        type: 'array',
        contains: {
          type: 'string',
        },
      }],
      isRequired: true,
    },
  },
} as const;
