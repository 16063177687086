/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { File } from './File';

export type SavePersonManualBiometricsRequest = {
  documentType: SavePersonManualBiometricsRequest.documentType;
  documentNumber: string;
  documentFrontImg: File;
  documentBackImg: File;
  faceImg: File;
  nome: string;
  cpf: string;
};

export namespace SavePersonManualBiometricsRequest {

  export enum documentType {
    RG = 'RG',
    CNH = 'CNH',
  }


}

