/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ContractTemplate = {
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    integrationKey: {
      type: 'string',
      isRequired: true,
    },
    parameters: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    hirerIds: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    templateContentId: {
      type: 'string',
      isRequired: true,
    },
    formBaseData: {
      type: 'FormBaseData',
    },
  },
} as const;
