/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalExtraSpecialty = {
  properties: {
    specialty: {
      type: 'string',
      isRequired: true,
    },
    type: {
      type: 'ProfessionalSpecialtyType',
      isRequired: true,
    },
    status: {
      type: 'ProfessionalExtraSpecialtyStatus',
      isRequired: true,
    },
    file: {
      type: 'File',
      isRequired: true,
    },
    id: {
      type: 'string',
    },
    cpf: {
      type: 'string',
    },
    toDelete: {
      type: 'boolean',
    },
  },
} as const;
