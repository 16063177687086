/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfessionalImportGetInstancesResponse } from '../models/ProfessionalImportGetInstancesResponse';
import type { Response } from '../models/Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SupApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Impersonate the doctor with the given CPF
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public impersonate(
    requestBody: {
      cpf?: string;
      userId?: string;
    },
  ): CancelablePromise<(Response & {
    data: any;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/impersonate',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return medical instances for the given instance
   * @returns ProfessionalImportGetInstancesResponse Returns success/APIError
   * @throws ApiError
   */
  public getShiftManagerInstances(): CancelablePromise<ProfessionalImportGetInstancesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/get-instances',
    });
  }

}
