/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MarketplaceProfessionalListRequest = {
  properties: {
    search: {
      type: 'string',
      isRequired: true,
      isNullable: true,
    },
    sorting: {
      type: 'all-of',
      contains: [{
        type: 'Sorting',
      }],
      isRequired: true,
      isNullable: true,
    },
    filters: {
      properties: {
        status: {
          type: 'array',
          contains: {
            type: 'string',
          },
          isNullable: true,
        },
        crmUFs: {
          type: 'array',
          contains: {
            type: 'string',
          },
        },
        specialties: {
          type: 'array',
          contains: {
            type: 'string',
          },
          isNullable: true,
        },
        registerNumber: {
          type: 'string',
        },
        registerUFs: {
          type: 'array',
          contains: {
            type: 'string',
          },
        },
        professionIds: {
          type: 'array',
          contains: {
            type: 'Profession',
          },
          isNullable: true,
        },
      },
      isRequired: true,
    },
    pagination: {
      type: 'PaginationRequest',
      isRequired: true,
    },
    legalEntityType: {
      type: 'LegalEntityType',
      isRequired: true,
    },
  },
} as const;
