/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListCompanyMembersData = {
  properties: {
    members: {
      type: 'array',
      contains: {
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
        },
      },
      isRequired: true,
    },
  },
} as const;
