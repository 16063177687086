/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserWorkflow = {
  properties: {
    steps: {
      type: 'array',
      contains: {
        type: 'UserWorkflowStep',
      },
    },
    workflow: {
      type: 'Workflow',
    },
    status: {
      type: 'WorkflowStatus',
    },
    statusReason: {
      type: 'string',
    },
    internalStatusReason: {
      type: 'string',
    },
    document: {
      type: 'File',
    },
  },
} as const;
