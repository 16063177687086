/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalInfoDocument = {
  type: 'all-of',
  contains: [{
    type: 'ProfessionalDocument',
  }, {
    properties: {
      professionDocumentConfigName: {
        type: 'string',
        isRequired: true,
      },
    },
  }],
} as const;
