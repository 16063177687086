/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AssignContractReviewer = {
  properties: {
    contractId: {
      type: 'string',
      isRequired: true,
    },
    contractReviewerId: {
      type: 'string',
      isRequired: true,
    },
    order: {
      type: 'number',
      isRequired: true,
    },
    contractReviewerType: {
      type: 'ContractReviewerType',
      isRequired: true,
    },
    reviewStatus: {
      type: 'ReviewStatus',
      isRequired: true,
    },
  },
} as const;
