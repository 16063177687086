/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { File } from './File';

export type ProfessionalDocument = {
  status: ProfessionalDocument.status;
  professionDocumentConfigId: string;
  files: Array<File>;
  toDelete?: boolean;
};

export namespace ProfessionalDocument {

  export enum status {
    VALID = 'VALID',
    INVALID = 'INVALID',
    VALIDATION_PENDING = 'VALIDATION_PENDING',
  }


}

