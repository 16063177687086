/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GdmApprovalRequest = {
  properties: {
    userId: {
      type: 'string',
      isRequired: true,
    },
    professionalId: {
      type: 'string',
      isRequired: true,
    },
    reason: {
      type: 'string',
      isRequired: true,
    },
    companyId: {
      type: 'string',
    },
    document: {
      type: 'File',
    },
    approvalSteps: {
      type: 'array',
      contains: {
        properties: {
          step: {
            type: 'ApprovalStep',
            isRequired: true,
          },
          approval: {
            type: 'boolean',
            isRequired: true,
          },
        },
      },
    },
  },
} as const;
