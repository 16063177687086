/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyBasicInfo = {
  properties: {
    cnpj: {
      type: 'string',
    },
    mainName: {
      type: 'string',
      isRequired: true,
    },
    publicName: {
      type: 'string',
    },
    juridicNature: {
      type: 'JuridicNature',
    },
    openingDate: {
      type: 'number',
      isRequired: true,
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    phones: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    mainCNAE: {
      type: 'CNAE',
    },
    secondaryCNAEs: {
      type: 'array',
      contains: {
        type: 'CNAE',
      },
    },
    address: {
      type: 'Address',
      isRequired: true,
    },
    members: {
      type: 'array',
      contains: {
        type: 'CompanyMember',
      },
      isRequired: true,
    },
  },
} as const;
