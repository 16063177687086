/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserTerm = {
  properties: {
    term: {
      type: 'Term',
      isRequired: true,
    },
    title: {
      type: 'string',
      isRequired: true,
    },
    content: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
