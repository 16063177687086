/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListProfessionSpecialtiesResponseData = {
  properties: {
    professionId: {
      type: 'string',
      isRequired: true,
    },
    specialties: {
      type: 'array',
      contains: {
        type: 'Specialty',
      },
      isRequired: true,
    },
  },
} as const;
