/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListMembersInfoResponse = {
  properties: {
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
    },
    phone: {
      type: 'string',
    },
    zipCode: {
      type: 'string',
    },
    street: {
      type: 'string',
    },
    number: {
      type: 'string',
    },
    complement: {
      type: 'string',
    },
    neighborhood: {
      type: 'string',
    },
    city: {
      type: 'string',
    },
    uf: {
      type: 'string',
    },
    maritalStatus: {
      type: 'MaritalStatus',
    },
    addressId: {
      type: 'string',
    },
  },
} as const;
