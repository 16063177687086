/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreateCommentResponse = {
  type: 'all-of',
  contains: [{
    type: 'Response',
  }, {
    properties: {
      data: {
        type: 'array',
        contains: {
          properties: {
            id: {
              type: 'string',
              isRequired: true,
            },
            content: {
              type: 'string',
              isRequired: true,
            },
            authorEmail: {
              type: 'string',
              isRequired: true,
            },
            timestamp: {
              type: 'string',
              isRequired: true,
            },
            attachments: {
              type: 'array',
              contains: {
                type: 'File',
              },
            },
          },
        },
      },
    },
  }],
} as const;
