/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OtherProfessionalRegister = {
  properties: {
    registerNumber: {
      type: 'string',
      isRequired: true,
    },
    registerUF: {
      type: 'UF',
      isRequired: true,
    },
    type: {
      type: 'DoctorCRMRegisterType',
      isRequired: true,
    },
    status: {
      type: 'DoctorCRMRegisterStatus',
      isRequired: true,
    },
    board: {
      type: 'Board',
      isRequired: true,
    },
  },
} as const;
