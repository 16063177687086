/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TenantStyle = {
  properties: {
    siteTitle: {
      type: 'string',
      isRequired: true,
    },
    favicon: {
      type: 'string',
      isRequired: true,
    },
    logo: {
      type: 'string',
      isRequired: true,
    },
    landpage: {
      type: 'string',
      isRequired: true,
    },
    theme: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
