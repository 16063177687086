/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserAuthResponseData = {
  properties: {
    newUser: {
      type: 'boolean',
      isRequired: true,
    },
    email: {
      type: 'string',
    },
    phone: {
      type: 'string',
    },
    emailVerified: {
      type: 'boolean',
      isRequired: true,
    },
    phoneVerified: {
      type: 'boolean',
      isRequired: true,
    },
  },
} as const;
