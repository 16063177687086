/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Hirer = {
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    cnpj: {
      type: 'string',
      isRequired: true,
    },
    companyName: {
      type: 'string',
      isRequired: true,
    },
    address: {
      type: 'Address',
      isRequired: true,
    },
    signers: {
      type: 'array',
      contains: {
        type: 'Signer',
      },
      isRequired: true,
    },
  },
} as const;
