/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApprovalCompetency = {
  description: `Payment value range that an a approver is allowed to approve.`,
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    min: {
      type: 'number',
    },
    max: {
      type: 'number',
    },
  },
} as const;
