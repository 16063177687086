/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CompanyStatus {
  VALID = 'VALID',
  PENDING = 'PENDING',
  INVALID = 'INVALID',
  INACTIVE = 'INACTIVE',
}
