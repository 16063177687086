/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DoctorBasicInfoResponseData = {
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    crm: {
      type: 'string',
      isRequired: true,
    },
    crmUF: {
      type: 'string',
      isRequired: true,
    },
    birthDate: {
      type: 'number',
      isRequired: true,
    },
    register: {
      type: 'ProfessionalRegister',
    },
  },
} as const;
