/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FormQuestion = {
  properties: {
    params: {
      properties: {
        type: {
          type: 'QuestionType',
          isRequired: true,
        },
        options: {
          type: 'array',
          contains: {
            properties: {
              label: {
                type: 'string',
                isRequired: true,
              },
              value: {
                type: 'string',
                isRequired: true,
              },
              id: {
                type: 'string',
                isRequired: true,
              },
              rules: {
                type: 'FormRules',
                isRequired: true,
              },
            },
          },
        },
        files: {
          type: 'array',
          contains: {
            type: 'File',
          },
        },
        rules: {
          type: 'FormRules',
          isRequired: true,
        },
        element: {
          type: 'Element',
        },
        placeholder: {
          type: 'string',
        },
      },
      isRequired: true,
    },
    id: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
