/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SearchPersonInfoResponse = {
  properties: {
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    birthDate: {
      type: 'string',
      isRequired: true,
      format: 'date',
    },
  },
} as const;
