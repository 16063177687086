/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdminValidationResult = {
  properties: {
    name: {
      type: 'string',
      isRequired: true,
    },
    messages: {
      type: 'array',
      contains: {
        type: 'AdminValidationMessage',
      },
      isRequired: true,
    },
    success: {
      type: 'boolean',
      isRequired: true,
    },
  },
} as const;
