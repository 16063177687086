/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Provider = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    slug: {
      type: 'string',
      isRequired: true,
    },
    display_name: {
      type: 'string',
      isRequired: true,
    },
    logo_uri: {
      type: 'string',
      isRequired: true,
    },
    domain: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
