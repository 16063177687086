/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProfessionDocumentType {
  DOCTOR_DEGREE = 'DOCTOR_DEGREE',
  DOCTOR_POSTGRADUATE_DEGREE = 'DOCTOR_POSTGRADUATE_DEGREE',
  DOCTOR_SPECIALIZATION_DEGREE = 'DOCTOR_SPECIALIZATION_DEGREE',
  DOCTOR_CRM = 'DOCTOR_CRM',
  DOCTOR_ACLS_CERTIFICATE = 'DOCTOR_ACLS_CERTIFICATE',
  DOCTOR_CARDIOLOGIST_CERTIFICATE = 'DOCTOR_CARDIOLOGIST_CERTIFICATE',
  DOCTOR_VACCINATION_CARD = 'DOCTOR_VACCINATION_CARD',
  DOCTOR_COVID_VACCINE = 'DOCTOR_COVID_VACCINE',
  DOCTOR_ETICAL_CERTIFICATE = 'DOCTOR_ETICAL_CERTIFICATE',
  DOCTOR_FISCAL_REGULATION_CERTIFICATE = 'DOCTOR_FISCAL_REGULATION_CERTIFICATE',
  SUMMARIZED_CURRICULUM = 'SUMMARIZED_CURRICULUM',
  DIGITAL_CERT_OWNERSHIP_TERM = 'DIGITAL_CERT_OWNERSHIP_TERM',
}
