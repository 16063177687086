/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetCompanyDocumentsResponse = {
  properties: {
    document: {
      type: 'CompanyDocument',
    },
    status: {
      type: 'CompanyDocumentStatus',
    },
  },
} as const;
