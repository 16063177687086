/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BussinessPartner = {
  properties: {
    id: {
      type: 'string',
    },
    professional_id: {
      type: 'string',
    },
    bussiness_partner_id: {
      type: 'string',
    },
    raw_data: {
      properties: {
      },
    },
  },
} as const;
