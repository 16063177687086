/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DoctorCRMRegisterType {
  MAIN = 'MAIN',
  SECONDARY = 'SECONDARY',
  TEMPORARY = 'TEMPORARY',
  UNINFORMED = 'UNINFORMED',
  FOREIGNER_STUDENT = 'FOREIGNER_STUDENT',
}
