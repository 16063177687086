/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WorkflowStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  ACTION_PENDING = 'ACTION_PENDING',
  APPROVED = 'APPROVED',
  DENIED_TEMPORARILY = 'DENIED_TEMPORARILY',
  DENIED_FOREVER = 'DENIED_FOREVER',
}
