/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApprovalStep {
  PERSONAL = 'PERSONAL',
  PROFESSIONAL = 'PROFESSIONAL',
  COMPANY = 'COMPANY',
  ACCREDITATION = 'ACCREDITATION',
}
