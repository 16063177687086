/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EditCommentRequest = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    content: {
      type: 'string',
      isRequired: true,
    },
    file: {
      type: 'File',
    },
  },
} as const;
