/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetBusinessPartnerBukrsResponse = {
  type: 'all-of',
  contains: [{
    type: 'Response',
  }, {
    properties: {
      data: {
        properties: {
          partner: {
            type: 'string',
            isRequired: true,
          },
          bukrsList: {
            type: 'array',
            contains: {
              properties: {
                name: {
                  type: 'string',
                  isRequired: true,
                },
                bukrs: {
                  type: 'string',
                  isRequired: true,
                },
              },
            },
            isRequired: true,
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
