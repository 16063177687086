/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetContractReviewerRequest = {
  properties: {
    id: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
  },
} as const;
