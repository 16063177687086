/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TenantConfig = {
  properties: {
    tenantId: {
      type: 'string',
      isRequired: true,
    },
    tenantConfigKey: {
      type: 'string',
      isRequired: true,
    },
    onboardingApiHost: {
      type: 'string',
      isRequired: true,
    },
    onboardingBackofficeHosts: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    onboardingUserHosts: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    style: {
      properties: {
        user: {
          type: 'TenantStyle',
          isRequired: true,
        },
        backoffice: {
          type: 'TenantStyle',
          isRequired: true,
        },
      },
      isRequired: true,
    },
  },
} as const;
