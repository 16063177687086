/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PersonDocumentConfig = {
  properties: {
    id: {
      type: 'string',
    },
    type: {
      type: 'PersonDocumentType',
      isRequired: true,
    },
    description: {
      type: 'string',
      isRequired: true,
    },
    allowed_file_types: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    required: {
      type: 'boolean',
      isRequired: true,
    },
    group_name: {
      type: 'string',
      isRequired: true,
    },
    active: {
      type: 'boolean',
      isRequired: true,
    },
  },
} as const;
