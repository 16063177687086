/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DoctorSavePersonalInfoRequest = {
  properties: {
    cpf: {
      type: 'string',
      isRequired: true,
    },
    crm: {
      type: 'string',
      isRequired: true,
    },
    crmUF: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    birthDate: {
      type: 'number',
      description: `unix timestamp`,
      isRequired: true,
    },
    gender: {
      type: 'Gender',
      isRequired: true,
    },
    maritalStatus: {
      type: 'MaritalStatus',
      isRequired: true,
    },
    rg: {
      type: 'string',
      isRequired: true,
    },
    rgState: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    phone: {
      type: 'string',
      isRequired: true,
    },
    nationality: {
      type: 'string',
      isRequired: true,
    },
    countryOrigin: {
      type: 'string',
      isRequired: true,
    },
    birthCity: {
      type: 'string',
      isRequired: true,
    },
    address: {
      type: 'AddressInfo',
    },
  },
} as const;
