/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PaginationMetadata = {
  properties: {
    total: {
      type: 'number',
    },
    limit: {
      type: 'number',
    },
    offset: {
      type: 'number',
    },
  },
} as const;
