/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CompanyValidationStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  VALID = 'VALID',
  INVALID = 'INVALID',
}
