/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StatusHistory = {
  properties: {
    eventId: {
      type: 'string',
    },
    time: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    previousStatus: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    statusReason: {
      type: 'string',
    },
    internalStatusReason: {
      type: 'string',
    },
    document: {
      type: 'File',
    },
    complianceRecommendation: {
      type: 'ComplianceRecommendation',
    },
    updateReason: {
      type: 'string',
    },
  },
} as const;
