/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyDocument = {
  properties: {
    files: {
      type: 'array',
      contains: {
        type: 'File',
      },
    },
    companyDocumentConfigId: {
      type: 'string',
    },
    toDelete: {
      type: 'boolean',
    },
    companyDocumentType: {
      type: 'string',
    },
  },
} as const;
