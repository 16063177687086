/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetContractStatusHistoryResponse = {
  type: 'all-of',
  contains: [{
    type: 'ListResponse',
  }, {
    properties: {
      data: {
        properties: {
          contractHistory: {
            type: 'array',
            contains: {
              type: 'ContractHistoryEntry',
            },
            isRequired: true,
          },
          assignmentsHistory: {
            type: 'array',
            contains: {
              type: 'ContractAssignmentHistoryEntry',
            },
            isRequired: true,
          },
        },
        isRequired: true,
      },
    },
  }],
} as const;
