/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PersonBankAccountStatus {
  PENDING = 'PENDING',
  VALIDATION_SENT = 'VALIDATION_SENT',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  VALID = 'VALID',
  INVALID = 'INVALID',
}
