/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListContractReviewersRequest = {
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    cpf: {
      type: 'string',
    },
    role: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
    search: {
      type: 'string',
    },
    sort: {
      type: 'string',
    },
  },
} as const;
