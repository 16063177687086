/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SaveProfessionalInfoResponseData = {
  properties: {
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    birthDate: {
      type: 'string',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
    },
    registerUF: {
      type: 'UF',
    },
    registerStatus: {
      type: 'ProfessionalRegisterStatus',
    },
    registerAdditionalData: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    gender: {
      type: 'Gender',
      isRequired: true,
    },
    maritalStatus: {
      type: 'MaritalStatus',
      isRequired: true,
    },
    rg: {
      type: 'string',
      isRequired: true,
    },
    rgState: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    phone: {
      type: 'string',
      isRequired: true,
    },
    nationality: {
      type: 'string',
      isRequired: true,
    },
    countryOrigin: {
      type: 'string',
      isRequired: true,
    },
    birthCity: {
      type: 'string',
      isRequired: true,
    },
    address: {
      type: 'AddressInfo',
    },
    board: {
      type: 'Board',
    },
  },
} as const;
