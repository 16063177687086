/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCompanyMembersResponse } from '../models/GetCompanyMembersResponse';
import type { ListMembersInfoRequest } from '../models/ListMembersInfoRequest';
import type { ListMembersInfoResponse } from '../models/ListMembersInfoResponse';
import type { NewSigner } from '../models/NewSigner';
import type { Response } from '../models/Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserActionTokenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Checks the given user action token
   * @returns Response Returns success true when token is valid
   * @throws ApiError
   */
  public check(): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/check',
    });
  }

  /**
   * Get members from a company given a action token without needing to sign in
   * @returns GetCompanyMembersResponse Returns members from the company which token's owner belongs
   * @throws ApiError
   */
  public getCompanyMembers(): CancelablePromise<GetCompanyMembersResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/get-company-members',
    });
  }

  /**
   * Create new signers based on data informed by contractee
   * @param requestBody
   * @returns Response Return sucess true
   * @throws ApiError
   */
  public createSigners(
    requestBody: {
      signers: Array<NewSigner>;
    },
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/create-signers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Upload company tax form according to a user id
   * @param requestBody
   * @returns Response Return sucess true
   * @throws ApiError
   */
  public uploadCompanyTaxForm(
    requestBody: {
      formURI: string;
      type: string;
    },
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/upload-company-tax-form',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List all required data from saved members by a cpf list
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public listMembersInfo(
    requestBody: ListMembersInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: Array<ListMembersInfoResponse>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/list-member-info-by-cpf',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

}
