/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanySearchInfoResponse = {
  type: 'all-of',
  contains: [{
    type: 'Response',
  }, {
    properties: {
      data: {
        type: 'CompanyBasicInfo',
        isRequired: true,
      },
      alert: {
        properties: {
          searchedCNPJ: {
            type: 'string',
            isRequired: true,
          },
          otherCNPJ: {
            type: 'array',
            contains: {
              type: 'string',
            },
            isRequired: true,
          },
        },
      },
    },
  }],
} as const;
