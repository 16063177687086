/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AdminProfessional = {
  properties: {
    id: {
      type: 'string',
    },
    personId: {
      type: 'string',
      isRequired: true,
    },
    professionId: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
    },
    register: {
      type: 'ProfessionalRegister',
    },
    digitalCertificate: {
      type: 'ProfessionalDigitalCertificate',
    },
    sync_status: {
      type: 'ProfessionalSyncStatus',
    },
    complianceRecommendation: {
      type: 'ComplianceRecommendation',
    },
  },
} as const;
